<script>
export default {
  props: {
    id :{
      required: true,
    },
    loggedIn: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      title: "",
      content: "",
    };
  },
  methods: {
    Back() {
      this.$emit("Back");
    },
    LoadArticle() {
      if(isNaN(Number(this.id))){
        return null;
      }
      return fetch(`actions/post_articles.php?id=${this.id}`).then((res) => {
        return res.json();
      });
    },
  },
  mounted() {
    this.LoadArticle().then((response) => {
      if(response.length > 0){
        this.title = response[0].title;
        this.content = response[0].content;
      }else{
        this.title = "Oops!";
        this.content = "This post does not exist yet. Please check back later.";
      }
    });
  },
};
</script>

<template>
  <h1>{{ title }}</h1>
  <div class="container row">
    <div class="w-0 sidebutton-container">
    <router-link :to="{name: 'home'}" class="button back">Back</router-link>
    <router-link v-if="loggedIn" :to="{name: 'create_article', params:{id: id}}" class="button back">Edit</router-link>
    </div>
    <div class="col">
      <div class="card ck-content card-article w-100" v-html="content"></div>
    </div>
  </div>
</template>