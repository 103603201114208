import { createRouter, createWebHistory } from "vue-router";
import Admin from "../templates/AdminPage.vue";
import MainApp from "../templates/MainApp.vue";
import CreateArticle from "../templates/CreateArticle.vue";
import PostArticle from "../templates/PostArticle.vue";
import AddPost from "../templates/AddPost.vue";

const routes = [
  { path: '/', component: MainApp, name:"home" },
  { path: '/create-article/:id', component:CreateArticle, name:"create_article", props: true },
  { path: '/post-article/:id', component:PostArticle, name:"post_article", props: true},
  { path: '/add-post', component:AddPost, name:"add_post", props: true},
  {    name: "admin",    path: "/admin",    component: Admin,  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
