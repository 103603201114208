<script>
import LoginForm from './LoginForm.vue';
import { toRef } from 'vue';

export default {
  components: {
    LoginForm,
  },
  props: {
    loggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props, context) {

    const onLogin = (arg) => {
      // Emit the event again with the payload
      context.emit('on-login');
    }
    return {
      onLogin
    }
  },
  data() {
    return {};
  },
  methods: {

  },
  mounted() {
  }

};
</script>

<template>
  <div>
    <login-form @on-login="onLogin" :logged-in="loggedIn">
      <h2>Admin Panel</h2>
      <div class="card">
        <router-link :to="{ name: 'add_post' }">Create new post</router-link>
        <br />
        <br />
        <router-link :to="{ name: 'create_article', params: { id: '-1' } }">Edit existing post</router-link>
      </div>
    </login-form>
  </div>
</template>