window.addEventListener('load', function () {

    (function () {
        var body = document.body, scrollPercent;
        var starfield = document.querySelector(".starfield");
        var starfieldN = document.querySelector(".starfield-near");
        window.addEventListener("scroll", function () {
            let html = document.documentElement;
            let height = Math.max(body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight);
            scrollPercent = 100 * window.scrollY / (height - window.innerHeight);
            starfield.style.backgroundPosition = "0px " + scrollPercent + "%";

            scrollPercent = 100 * window.scrollY / (height - window.innerHeight);
            starfieldN.style.backgroundPosition = "0px " + scrollPercent + "%";
        });
    })();
});