<script>
import LoginForm from './LoginForm.vue';
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default {
  components: {
    LoginForm
  },
  props: {
    loggedIn: {
      type: Boolean,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();


    const title = ref("");
    const content = ref("");
    const url = ref("");
    const content_url = ref("");
    const year = ref(new Date().getFullYear());
    const postType = ref(0);
    const is_external = ref(false);
    const category_id = ref(0);


    //const categories = ref([]);
    const error = ref({
      status:""
    });

    const onLogin = () => {
      // Emit the event again with the payload
      context.emit('on-login');
    }

    const CreationSubmit = () => {
      //validate all data, mainly check if strings are empty and if year is between 2000 and current year
      console.log("trying to submit");
      if (title.value == "") {
        error.value.status = "<span style='color:red'>Title cannot be empty!</span>";
        document.getElementById("creationStatus").classList.remove("appear");
        setTimeout(() => {
          document.getElementById("creationStatus").classList.add("appear");
        }, 100);
        return;
      }
      if (content.value == "") {
        error.value.status = "<span style='color:red'>Content cannot be empty!</span>";
        document.getElementById("creationStatus").classList.remove("appear");
        setTimeout(() => {
          document.getElementById("creationStatus").classList.add("appear");
        }, 100);
        return;
      }
      if (url.value == "") {
        url.value = "#";
      }

      if (content_url.value == "") {
        error.value.status = "<span style='color:red'>Image or video URL cannot be empty!</span>";
        document.getElementById("creationStatus").classList.remove("appear");
        setTimeout(() => {
          document.getElementById("creationStatus").classList.add("appear");
        }, 100);
        return;
      }

      if (year.value < 2000 || year.value > new Date().getFullYear()+1) {
        error.value.status = "<span style='color:red'>Year must be between 2000 and current year!</span>";
        document.getElementById("creationStatus").classList.remove("appear");
        setTimeout(() => {
          document.getElementById("creationStatus").classList.add("appear");
        }, 100);
        return;
      }
      if (postType.value < 0 || postType.value > 1) {
        error.value.status = "<span style='color:red'>Post type must be 0 or 1!</span>";
        document.getElementById("creationStatus").classList.remove("appear");
        setTimeout(() => {
          document.getElementById("creationStatus").classList.add("appear");
        }, 100);
        return;
      }
      console.log(category_id.value);
      if (category_id.value == 0) {
        error.value.status = "<span style='color:red'>Category must be selected!</span>";
        document.getElementById("creationStatus").classList.remove("appear");
        setTimeout(() => {
          document.getElementById("creationStatus").classList.add("appear");
        }, 100);
        return;
      }

      console.log("submitting");
      let data = new FormData();
      data.append("title", title.value);
      // 0 image 1 video
      data.append("type", postType.value);
      data.append("url", url.value);
      data.append("is_external_url", is_external.value);
      data.append("content_url", content_url.value);
      data.append("content", content.value);
      // one of possible ids, gotta get them first to check
      data.append("category_id", category_id.value);
      data.append("year", year.value);

      data.append("token", localStorage.getItem("token"));
      data.append("sec_token", document.querySelector("input[name='sec_token']").value);
      fetch("actions/add_post.php", {
        method: "POST",
        body: data
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
      console.log(res);
          if (res == true) {
            error.value.status =
              "<span style='color:lime'>Successfully saved!</span>";
            document
              .getElementById("creationStatus")
              .classList.remove("appear");
            setTimeout(() => {
              document.getElementById("creationStatus").classList.add("appear");
            }, 100);
          } else {
            error.value.status = "<span style='color:red'>Error!</span>";
            document
              .getElementById("creationStatus")
              .classList.remove("appear");
            setTimeout(() => {
              document.getElementById("creationStatus").classList.add("appear");
            }, 100);
          }
        })
        .catch(() => {
          error.value.status = "<span style='color:red'>Error!</span>";
          document.getElementById("creationStatus").classList.remove("appear");
          setTimeout(() => {
            document.getElementById("creationStatus").classList.add("appear");
          }, 100);
        });
    }

    const LoadArticle = async () => {
      if (isNaN(Number(route.params.id))) {
        return null;
      }
      let response = await fetch(`actions/post_categories.php`);
      let json = await response.json();

      if (
        json.length > 0 &&
        json[0].content != undefined &&
        json[0].content != null
      ) {
        //categories.value = json[0].content;
        //console.log(categories.value);
      }
    }
    LoadArticle();

    return {
      //categories,
      error,
      onLogin,
      CreationSubmit,
      LoadArticle,
      title,
      content,
      url,
      content_url,
      year,
      postType,
      is_external,
      category_id
    }
  }
};
</script>

<template>
  <login-form @on-login="onLogin" :logged-in="loggedIn">
    <h1>Create a new Post</h1>
    <div class="container row">
      <div class="w-0 sidebutton-container">
        <router-link :to="{ name: 'admin' }" class="button back">Back</router-link>
      </div>
      <div class="col">
        <form @submit.prevent="CreationSubmit" action="actions/add_post.php" method="POST" class="card card-article w-100"
          style="min-width: 400px;">
          <div class="d-flex flex-column">
            <!-- form inputs -->
            <label for="title">Title:</label>
            <input type="text" id="title" v-model="title" required />
            <br />
            <!-- category dropdown -->
            <label for="category">Category:</label>
            <select id="category" v-model="category_id" required>
              <option v-for="category in categories" v-bind:key="category.id" :value="category.id">{{ category.name }}
              </option>
            </select>

            <label for="content">Content:</label>
            <textarea id="content" rows="8" v-model="content" required></textarea>
            <br />
            <!-- checkbox for Is External -->
            <div>
              <label for="is_external">Is External URL:</label>
              <input type="checkbox" id="is_external" v-model="is_external" />
            </div>

            <template v-if="is_external">
              <label for="url">URL:</label>
              <input type="url" id="url" v-model="url" />
            </template>

            <br />
            <!-- year dropdown -->
            <label for="year">Year:</label>
            <select id="year" v-model="year" required>
              <option v-for="i in 30" v-bind:key="i + 1" :value="new Date().getFullYear() - i + 1">{{ new Date().getFullYear()
                -
                i + 1 }}</option>
            </select>
            <br />
            <!-- post type dropdown -->
            <label for="postType">Post type:</label>
            <select id="postType" v-model="postType" required>
              <option value="0">Image</option>
              <option value="1">Video</option>
            </select>
            <br />
            <!-- content url, image or video depending on post type selection -->
            <label for="content_url">{{ postType == 0 ? "Image" : "Video" }} URL:</label>
            <input type="text" id="content_url" v-model="content_url" required />
            <br />
            <div>
              <span id="creationStatus" v-html="error.status"></span>
            </div>
            <span class="w-100" style="display: flex">
              <input type="submit" class="btn-green ml-auto" value="Submit" />
            </span>
          </div>
        </form>
      </div>
    </div>
</login-form></template>