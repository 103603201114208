<script>
import LoginForm from './LoginForm.vue';
import { toRefs, computed, toRef, ref, reactive, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    LoginForm,
    Editor
  },
  props: {
    loggedIn: {
      type: Boolean,
      required: true
    },
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const exists = ref(true);

    const creation = ref({
      data: "",
      config: {
        link: {
          addTargetToExternalLinks: true,
        },
        language: "en",
        plugins: 'lists link image table code help wordcount autolink media',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | forecolor backcolor | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        skin: "oxide-dark",
        content_css: "dark",
        color_cols: 10,
      },
      selected: 0,
      original: "",
      prevId: 0,
      status: "",
    })

    const onLogin = () => {
      // Emit the event again with the payload
      context.emit('on-login');
    }

    const CreationChangePost = () => {
      if (this.creation.original != this.creation.data) {
        var overwrite = confirm(
          "You have unsaved changes. Do you want to overwrite them?"
        );
        if (!overwrite) {
          if (this.creation.selected != this.creation.prevId) {
            this.creation.selected = this.creation.prevId;
          }
          return;
        }
        this.creation.status = "";
      }
      this.creation.prevId = this.creation.selected;
    }
    const CreationSubmit = () => {
      let data = new FormData();
      data.append("id", route.params.id);
      data.append("content", creation.value.data);
      data.append("token", localStorage.getItem("token"));
      data.append("sec_token", document.querySelector("input[name='sec_token']").value);
      fetch("actions/create_article.php", {
        method: "POST",
        body: data
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (res == true) {
            creation.value.status =
              "<span style='color:lime'>Successfully saved!</span>";
            document
              .getElementById("creationStatus")
              .classList.remove("appear");
            setTimeout(() => {
              document.getElementById("creationStatus").classList.add("appear");
            }, 100);
            creation.value.original = creation.value.data;
          } else {
            creation.value.status = "<span style='color:red'>Error! - Server denied</span>";
            document
              .getElementById("creationStatus")
              .classList.remove("appear");
            setTimeout(() => {
              document.getElementById("creationStatus").classList.add("appear");
            }, 100);
          }
        })
        .catch((e) => {
          creation.value.status = "<span style='color:red'>Error! - "+ e.toString() +"</span>";
          document.getElementById("creationStatus").classList.remove("appear");
          setTimeout(() => {
            document.getElementById("creationStatus").classList.add("appear");
          }, 100);
        });
    }

    const LoadArticle = async () => {
      if (isNaN(Number(route.params.id))) {
        return null;
      }
      let response = await fetch(`actions/post_articles.php?id=${route.params.id}`);
      let json = await response.json();

      if (
        json.length > 0 &&
        json[0].content != undefined &&
        json[0].content != null
      ) {
        creation.value.original = json[0].content;
        creation.value.data = json[0].content;
        exists.value = true;
      } else {
        creation.value.original = "";
        creation.value.data = "";
        exists.value = false;
      }
    }
    LoadArticle();

    return {
      exists,
      creation,
      onLogin,
      CreationChangePost,
      CreationSubmit,
      LoadArticle,
    }
  }
};
</script>

<template>
  <div>
    <login-form @on-login="onLogin" :logged-in="loggedIn">
      <h1>Create an Acticle</h1>
      <div class="container row">
        <div class="w-0 sidebutton-container">
          <router-link :to="{ name: 'post_article', params: { id: $route.params.id } }"
            class="button back">Back</router-link>
        </div>
        <div class="col">
          <div class="card card-article w-100">
            <template v-if="exists">
              <form @submit.prevent="CreationSubmit" action="actions/create_article.php" method="POST">
                <Editor api-key="qloujw26mhcgoyr3bw1jbw64qobprpth4kf3tfdgvorzni3j" :init="creation.config"
                  v-model="creation.data" />
                <br />
                <div>
                  <span id="creationStatus" v-html="creation.status"></span>
                </div>
                <span class="w-100" style="display: flex">
                  <input type="submit" class="btn-green ml-auto" value="Save" />
                </span>
              </form>
            </template>
            <template v-else>
              <p>
                This post doesn't have an article.
              </p>
              <button class="button" @click="exists = true;">Create Post</button>
            </template>
          </div>
        </div>
      </div>
    </login-form>
  </div>
</template>