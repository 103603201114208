import { createApp } from "vue";
import MainApp from '../templates/MainApp.vue';
import router from "./router";


const App = {
    components: {
        MainApp: MainApp
    }
};

const app = createApp(App);

app.config.errorHandler = (err, vm, info) => {
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.

    let data = new FormData();
    data.append("error", JSON.stringify({err, info}));
    fetch("actions/log_error.php", {
        method: "POST",
        body: data,
    })

};
app.use(router);
app.mount("#mountpoint");

export default app;