<script>
import PostCategory from "../templates/PostCategory.vue";
import PostArticle from "../templates/PostArticle.vue";
import AdminPage from "../templates/AdminPage.vue";
import CreateArticle from "../templates/CreateArticle.vue";
import AddPost from "../templates/AddPost.vue";
import LoginForm from "../templates/LoginForm.vue";
import { useRouter, useRoute } from 'vue-router'
import { computed, ref } from 'vue';
import Editor from '@tinymce/tinymce-vue'


export default {
  setup(props, context) {
    const router = useRouter()
    const route = useRoute()

    return{
      router,
      route,
    }
  },
  components: {
    PostCategory,
    PostArticle,
    AdminPage,
    CreateArticle,
    AddPost,
  },
  props: [],
  data() {
    return {
      state: {
        loading: true,
        post_categories: [],
        posts: [],
        loggedIn: false,
      },
      page: "home",
      postId: 0,
    };
  },
  methods: {
    toggleloading() {
      this.state.loading = !this.state.loading;
    },
    async checkToken() {
      try {
        const response = await fetch('actions/internal/login.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: localStorage.getItem('token')
          })
        });

        const data = await response.json();
        if (data.success) {
          this.state.loggedIn = true;
          //console.log("loggedin");
        } else {
          console.log(data.message);
          //console.log("loggedin failded");
        }

      } catch (error) {
        //console.log(error.message);
      }
    },
    loadCats() {
      return fetch("actions/post_categories.php").then((res) => {
        return res.json();
      });
    },
    loadPosts(categories) {
      let json = {};
      for (let i = 0; i < categories.length; i++) {
        fetch(`actions/posts.php?id=${categories[i].id}`).then((res) => {
          json = res.json();
        });
      }
      return json;
    },
    loadCategory(id) {
      return fetch(`actions/posts.php?id=${id}`).then((res) => {
        return res.json();
      });
    },
    GoToPost(id) {
      this.page = "post";
      this.postId = id;
    },
    Home() {
      this.page = "home";
    },
    Games() {
      this.page = "games";
    },
    Music() {
      this.page = "music";
    },
    CreateArticle() {
      this.page = "create_article";
    },
    isLocalHost() {
      if (
        location.hostname === "localhost" ||
        location.hostname === "127.0.0.1"
      ) {
        return true;
      }
      return false;
    },
    logOut(){
      localStorage.removeItem('token');

      let response = fetch('actions/internal/logout.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response)=>{let res = response.json()});

      this.state.loggedIn = false;
      useRouter.addRoute("home");
    },
    onLogin(arg) {
      // Emit the event again with the payload
      this.state.loggedIn = true;
    },
  },
  mounted() {
    this.checkToken();
    
    this.loadCats().then((response) => {
      this.state.post_categories = response.sort(function (a, b) {
        return b.order - a.order;
      });
      for (let i = 0; i < this.state.post_categories.length; i++) {
        this.state.post_categories[i].posts = [];
        for (let j = 0; j < this.state.post_categories[i].count; j++) {
          this.state.post_categories[i].posts.push({});
        }
        this.loadCategory(this.state.post_categories[i].id).then((posts) => {
          this.state.post_categories[i].posts = posts.sort(
            (a, b) => parseInt(b.year) - parseInt(a.year)
          );
        });
      }
    });

      // Select random bg
    const classNames = ['nebula1', 'nebula2', 'nebula3', 'nebula4'];
    const randomIndex = Math.floor(Math.random() * classNames.length);
    const bodyElement = document.querySelector('body');
    bodyElement.classList.add(classNames[randomIndex]);
  },
};
</script>

<template>
  <div class="navbar">
    <div class="namebar">
      <a class="h1" href="/">Kryštof Ježek</a>
      <a style="margin:auto 1em;" href="mailto:krystof@webik150.com">krystof@webik150.com</a>
    </div>
    <div class="navigation">
      <!--<a href="#" @click.prevent="Home" :selected="page === 'home' ? true : null">Home</a>-->
      <router-link :to="{ name: 'home' }">Home</router-link>
      <!--<a href="#contact">Contact</a>
      <a href="#" @click.prevent="Music" :selected="page === 'music' ? true : null"
        >Music</a
      >-->
      <a href="https://redtorch.cz" target="_blank"><span id="red">Red&nbsp;Torch</span></a>
      <router-link v-if="state.loggedIn" :to="{ name: 'admin' }">admin panel</router-link>
      <a href="#" v-if="state.loggedIn" @click.prevent="logOut()">Logout</a>
    </div>
  </div>
  <div class="starfield-near">
    <div class="content starfield">
      <template v-if="$route.name === 'admin'">
        <admin-page @on-login="onLogin" :logged-in="state.loggedIn"></admin-page>
      </template>
      <template v-else-if="$route.name === 'post_article'">
        <post-article @back="Home" :id="$route.params.id" :logged-in="state.loggedIn"></post-article>
      </template>
      <template v-else-if="$route.name === 'create_article'">
        <create-article @on-login="onLogin" :logged-in="state.loggedIn"></create-article>
      </template>
      <template v-else-if="$route.name === 'add_post'">
        <add-post @on-login="onLogin" :categories="state.post_categories" :logged-in="state.loggedIn"></add-post>
      </template>
      <template v-else>
        <img src="/img/webik150v3wHITE.png"/>
        <div class="card firstcard">
          <h3>Heya!</h3>
          <p>
            Welcome to my website. I'm webik150, a game designer, programmer and an artist from
            Czechia. I like pretty things. I don't like bad things... well sometimes I like little bad things. <br />
            I've been coding for about
            {{ new Date().getFullYear() - 2010 }} years now.
          </p>
          <p>
            Currently studying Game Design @ <a href="https://hernidesign.famu.cz/" target="_blank"><b>FAMU</b></a>.
          </p>
          <b>
            Where to find me:
          </b>
          <div style="text-align: center;">
            
            <p>
              Mastodon: <a href="https://mastodon.gamedev.place/@webik150" target="_blank"><b>@webik150@mastodon.gamedev.place</b></a>
            </p>
            <p>
              Email: <a href="mailto:krystof@webik150.com" target="_blank"><b>krystof@webik150.com</b></a>
            </p>
            <p>
              Twitter: <a href="https://twitter.com/webik150" target="_blank"><b>@webik150</b></a>
            </p>
            <p>
              itch.io: <a href="https://webik150.itch.io/" target="_blank"><b>webik150</b></a>
            </p>
          </div>
        </div>
        <div>
          <post-category v-for="cat in state.post_categories" :id="cat.id" :key="cat.id" :name="cat.name"
            :posts="cat.posts" :order="cat.order" @go-to-post="GoToPost">
          </post-category>
        </div>
      </template>
    </div>
  </div>
</template>