<script>
import Post from "./TimelinePost.vue";

export default {
  components:{
    TimelinePost: Post
  },
  props: ["id", "name", "order", "posts", "count", "loading"],
  data() {
    return {    };
  },
  methods: {
    GoToPost(id) {
        this.$emit('GoToPost', id);
    },
  },
};
</script>

<template>
  <div>
    <h1 class="categoryHeader">
      <span>{{ name }}</span>
    </h1>
    <div class="category">
      <timeline-post
        v-for="post in posts"
        :key="post.id"
        :id="post.id"
        :title="post.title"
        :type="post.type"
        :url="post.url"
        :content_url="post.content_url"
        :content="post.content"
        :is_external_url="post.is_external_url"
        :loading="loading"
        @go-to-post="GoToPost"
      ></timeline-post>
    </div>
  </div>
</template>