<script>
import { ref } from 'vue';
import { defineProps } from 'vue';
import { toRefs } from 'vue';

export default {
  name: 'LoginForm',
  props:{
    loggedIn:{
      type: Boolean,
      required: true
    }
  },
  setup(props, context) {
    const username = ref('');
    const password = ref('');
    const errorMessage = ref('');

    const submit = async () => {
      try {
        const response = await fetch('actions/internal/login.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: username.value,
            password: password.value
          })
        });

        if (!response.ok) {
          throw new Error('Invalid credentials');
        }

        const data = await response.json();
        if (data.success) {
          localStorage.setItem('token', data.token);
          context.emit('on-login');
        } else {
          errorMessage.value = data.message;
        }

      } catch (error) {
        errorMessage.value = error.message;
      }
    };


    const onLogin =()=> {
      // Emit the event again with the payload
      context.emit('on-login');
    }

    return {
      username,
      password,
      errorMessage,
      submit,
      onLogin
    };
  }
};
</script>
  

<template>
  <div class="card" v-if="!loggedIn">
    <h2>Login</h2>
    <form @submit.prevent="submit">
      <label for="username">Email:</label>
      <input type="text" id="username" v-model="username" required>
      <br>
      <label for="password">Password:</label>
      <input type="password" id="password" v-model="password" required>
      <br>
      <button type="submit">Login</button>
    </form>
    <p class="warning" v-if="errorMessage">{{ errorMessage }}</p>
  </div>
  <template v-else>
    <slot></slot>
  </template>
</template>
  